import { Link } from "react-router-dom";
import { generateCategories } from "./utils";
import { generateRecipeSlug } from "../../utils";

type Props = {
  data: any;
};

const Card: React.FC<Props> = ({ data }) => {
  return (
    <Link to={generateRecipeSlug(data.title)}>
      <div className="rounded-2xl bg-white p-4 shadow-sm">
        <div
          className="h-40 w-full rounded-xl bg-cover bg-center"
          style={{ backgroundImage: 'url("' + data.thumbnail + '")' }}
        ></div>
        <h1 className={"mt-2 truncate font-medium"}>{data.title}</h1>
        <div className="mt-2 flex">
          <div className="flex">
            <i className="fa-solid fa-blender relative top-1 text-xs text-slate-300"></i>
            <p className="ml-1 text-sm">{data.preparation_duration}</p>
          </div>
          <div className="ml-4 flex">
            <i className="fa-solid fa-fire-burner relative top-1 text-xs text-slate-300"></i>
            <p className="ml-1 text-sm">{data.cooking_duration}</p>
          </div>
          <div className="ml-4 flex">
            <i className="fa-solid fa-user relative top-1 text-xs text-slate-300"></i>
            <p className="ml-1 text-sm">{data.nb_person}</p>
          </div>
        </div>
        <div className="mt-2 flex">
          {data.categories.map((d: string, key: number) => (
            <div key={key}>{generateCategories(d)}</div>
          ))}
          <p className="ml-auto text-sm text-orange">
            Voir plus <i className="fa-solid fa-arrow-right"></i>
          </p>
        </div>
      </div>
    </Link>
  );
};

export default Card;
