type Props = {
  image: any;
  title: string;
  onClick: any;
  selected: boolean;
};

const CategoryBadge: React.FC<Props> = ({ image, title, onClick, selected }) => {
  return (
    <div
      className={
        (selected ? "bg-orange text-white" : "bg-white") +
        " mx-2 flex flex-shrink-0 cursor-pointer items-center justify-center rounded-2xl px-4 py-2 shadow-sm"
      }
      onClick={onClick}
    >
      {image !== null && <img src={image} alt={""} className="mr-2 h-8 w-8 rounded-lg" />}
      <p className="whitespace-nowrap">{title}</p>
    </div>
  );
};

export default CategoryBadge;
