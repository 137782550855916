import { useState } from "react";
import React from "react";

type Props = {
  text: string;
  fullBackground?: boolean;
};

const CheckableCard: React.FC<Props> = ({ text, fullBackground = false }) => {
  const [checked, setChecked] = useState<boolean>(false);

  return (
    <div
      className={
        (fullBackground ? "!w-full" : "") +
        (checked ? " opacity-50" : "") +
        " mb-2 cursor-pointer select-none rounded-xl bg-white p-4 shadow-sm sm:mx-1 sm:w-fit"
      }
      onClick={() => setChecked(!checked)}
    >
      <div className="flex">
        <div className="relative top-1 mr-2 h-4 w-4 flex-shrink-0 rounded-md border border-slate-300">
          {checked && <i className="fa-solid fa-check relative bottom-[5px] text-xl text-orange"></i>}
        </div>
        <p className="">{text}</p>
      </div>
    </div>
  );
};

export default CheckableCard;
