import { Link } from "react-router-dom";

const Errorpage: React.FC = () => {
  return (
    <div className="flex h-screen w-screen items-center justify-center bg-beige">
      <div className="w-11/12 text-slate-700">
        <h1 className="text-center text-6xl font-bold">
          404 ! <br />
          <span className="!text-2xl">Il semblerait que la recette n'existe pas</span>
        </h1>
        <Link to={"/"}>
          <p className="mt-2 text-center underline">Retour à l'accueil</p>
        </Link>
      </div>
    </div>
  );
};

export default Errorpage;
