import Badge from "../Badge/Badge";

export const generateCategories = (category: string) => {
  switch (category) {
    case "meal":
      return <Badge name="Plat" />;
    case "dessert":
      return <Badge name="Dessert" />;
    case "drink":
      return <Badge name="Boisson" />;
    case "entry":
      return <Badge name="Entrée" />;
    case "sauce":
      return <Badge name="Sauce" />;
    case "aperitif":
      return <Badge name="Apéro" />;
    case "other":
    default:
      return <Badge name="Autre" />;
  }
};
