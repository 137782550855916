type Props = {
  name: string;
};

const Badge: React.FC<Props> = ({ name }) => {
  return (
    <div className="mr-1 w-fit rounded bg-slate-50 px-2 py-1 text-sm">
      <p>{name}</p>
    </div>
  );
};

export default Badge;
