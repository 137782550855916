import React, { createContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { compareByTitle } from "./utils";
import LoadingPage from "./pages/LoadingPage/LoadingPage";
import ScrollToTop from "./components/ScrollToTop";

export const RecipesContext = createContext<any>(null);

const App: React.FC<any> = () => {
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    fetch("https://dashboard.sebastien-os.fr/api/recipes/all")
      .then((response) => response.json())
      .then((data) => data.sort(compareByTitle))
      .then((json) => setData(json))
      .catch((error) => console.error(error));
  }, []);

  if (!data) return <LoadingPage />;

  return (
    <RecipesContext.Provider value={data}>
      <div className="bg-beige font-light text-slate-700">
        <ScrollToTop />
        <Outlet />
      </div>
    </RecipesContext.Provider>
  );
};

export default App;
