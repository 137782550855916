import ReactDOM from 'react-dom/client';
import './index.css';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Errorpage from './pages/Errorpage/Errorpage';
import RecipePage from './pages/RecipePage/RecipePage';
import AllRecipesPage from './pages/AllRecipesPage/AllRecipesPage';
import App from './App';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <Errorpage />,
    children: [
      {
        path: '/',
        element: <AllRecipesPage />,
      },
      {
        path: '/:recipeId',
        element: <RecipePage />
      }
    ]
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <RouterProvider router={router} />
);
